<template>
    <v-container>
        <div class="table-options">
            <v-btn class="my-2" dark small color="#027a00" @click="createVoucher">
                <v-icon dark>mdi-plus</v-icon>Create
            </v-btn>
        </div>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="vouchers"
            :items-per-page="itemsPerPage"
            :loading="loading"
            hide-default-footer
            class="elevation-1"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td @click="handleClick('show', props.item)">{{ props.item.team.title }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.member.firstName }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.member.lastName }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.description }}</td>
                    <td>
                        <div class="buttons">
                            <v-btn class="mx-2" dark small color="#004cff" @click="handleClick('edit', props.item)">
                                <v-icon dark>mdi-account-edit</v-icon>
                            </v-btn>
                            <delete-button @delete-entry="onDelete(props.item)" />
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
        />
    </v-container>
</template>

<script>
    import moment from 'moment'
    import voucherService from '@/services/voucher-service'
    import teamService from '@/services/team-service'
    import memberService from '@/services/member-service'
    import DeleteButton from '@/components/DeleteButton'

    export default {
        name: 'VoucherRoute',
        components: { DeleteButton },
        data: () => {
            return {
                vouchers: [],
                filterFields: [
                    {
                        name: 'Member',
                        key: 'member',
                        type: 'dropdown',
                        itemText: ['firstName', 'lastName'],
                        items: [],
                        itemValue: 'id',
                        prependIcon: 'mdi-account'
                    },
                    {
                        name: 'Team',
                        key: 'team',
                        type: 'dropdown',
                        itemText: ['title'],
                        items: [],
                        itemValue: 'id',
                        prependIcon: 'mdi-account-group'
                    }
                ],
                headers: [
                    { text: 'Team Title', value: 'title' },
                    { text: 'Member First Name', value: 'firstName' },
                    { text: 'Member Last Name', value: 'lastName' },
                    { text: 'Description', value: 'description' }
                ],
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                loading: true
            }
        },
        watch: {
            page() {
                var path = `/vouchers?page=${this.page}`
                if (this.$route.fullPath !== path) {
                    this.$router.push(path)
                }
                this.fetchAllVouchers()
            },
            $route(to, from) {
                if (to !== from) {
                    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
                    this.fetchAllVouchers()
                }
            }
        },
        created() {
            this.fetchAllVouchers()
            this.fetchAllMembers()
            this.fetchAllTeams()
            this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
        },
        methods: {
            handleClick(mode, item) {
                this.$router.push(`/vouchers/${item.id}/${mode}?page=${this.page}`)
            },
            onDelete(item) {
                voucherService.deleteVoucherById(item.id).then(() => {
                    this.fetchAllVouchers()
                })
            },
            createVoucher() {
                this.$router.push(`/vouchers/create?page=${this.page}`)
            },
            fetchAllVouchers() {
                this.loading = true
                let filter = null
                voucherService.getVouchersByFilter(this.page, filter).then(resp => {
                    this.vouchers = resp.data.data
                    this.pageCount = resp.data.last_page
                    this.loading = false
                })
            },
            fetchAllTeams() {
                teamService.getAllTeams().then(resp => {
                    this.filterFields[1].items = resp.data.data
                })
            },
            fetchAllMembers() {
                memberService.getAllMembers().then(resp => {
                    this.filterFields[0].items = resp.data.data
                })
            },
            formatDate(date, format) {
                return moment(date).format(format)
            },
            filterChanged(filterObject) {
                if (Object.keys(filterObject).length > 0) {
                    voucherService.getVouchersByFilter(filterObject).then(resp => {
                        this.vouchers = resp.data.data
                    })
                } else {
                    this.fetchAllVouchers()
                }
            }
        }
    }
</script>

<style scoped>
.search {
    max-width: 300px;
}
.table-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
td {
    min-width: 150px;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
</style>
